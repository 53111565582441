import { ethers } from "ethers";
import { NETWORKS } from "../networks";
const decodeLogs = ({ abi, logs }) => {
    const iface = new ethers.utils.Interface(abi);
    return logs.map((log) => {
        try {
            return iface.parseLog(log);
        } catch (e) {
            return null;
        }
    });
};

const getNetwork = (backend_chain_name) => {
    const networks = Object.values(NETWORKS);
    const network = networks.find(
        (network) => network?.bname === backend_chain_name
    );
    return network;
};

const getExplorerLink = (backend_chain_name) => {
    const network = getNetwork(backend_chain_name);
    return network?.scanner;
};

export { decodeLogs, getExplorerLink, getNetwork };
