import {
    ConnectEmbed,
    useAddress,
    useAuth,
    useWallet,
} from "@thirdweb-dev/react";
import Typography from "../../components/Typography/Typography";
import "./auth.scss";
import React, { useEffect, useState } from "react";
import { useLoginMutation } from "../../services/authService";
import { ACCESS_TOKEN_NAME, REFRESH_TOKEN_NAME } from "../../constants";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ToastComponent from "../../components/Toast/ToastComponent";
import { authActions } from "../../services/authSlice";
import { useDispatch } from "react-redux";
import { logEvent, updateAmplitudeUserId } from "../../utils/amplitudeUtlis";
import Loading from "../../components/LoadingSkeleton/Loading";

export default function CommonAuthComponent({ variant = "signin" }) {
    const thirdwebAuth = useAuth();
    const address = useAddress();
    const wallet = useWallet("embeddedWallet");
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    var [toast, setToast] = useState(false);
    const [signIn] = useLoginMutation();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const invitation = queryParams.get("invitation");

    const dispatch = useDispatch();

    useEffect(() => {
        if (address) {
            login();
        }
    }, [address]);

    const login = async () => {
        setIsLoading(true);
        const payload = await thirdwebAuth.login();
        const email = await wallet.getEmail();
        const metaData = wallet.getMeta();
        const invitationToken = invitation ? invitation : "";

        logEvent({
            eventName: invitationToken
                ? "user_login_initiated"
                : "tenant_login_initiated",
            eventProperties: "",
        });

        updateAmplitudeUserId(email);

        const signInRes = await signIn({
            payload,
            email,
            metaData,
            invitationToken,
        });

        if (signInRes.data) {
            if (
                signInRes.data.data?.tokens.access ||
                signInRes.data.data?.tokens.refresh
            ) {
                sessionStorage.setItem(
                    ACCESS_TOKEN_NAME,
                    signInRes.data.data.tokens.access
                );

                localStorage.setItem(
                    REFRESH_TOKEN_NAME,
                    signInRes.data.data?.tokens.refresh
                );

                dispatch(authActions.login(signInRes.data.data.user));

                logEvent({
                    eventName: invitationToken
                        ? "user_login_completed"
                        : "tenant_login_completed",
                    eventProperties: {
                        email: email,
                        role: signInRes?.data?.data?.user?.role,
                        status: "Success",
                    },
                });
                if (signInRes.data.data.user.id === null) {
                    navigate("/signin");
                } else if (signInRes.data.data.user.tenant === null) {
                    navigate("/onboarding");
                } else {
                    navigate("/overview");
                }
            }
        } else {
            setToast((toast) => !toast);
            setToastMessage(signInRes.error.data?.errors[0]);
            logEvent({
                eventName: invitationToken
                    ? "user_login_failed"
                    : "tenant_login_failed",
                eventProperties: {
                    status: "Failed",
                },
            });

            setShowToast(true);
        }
        setIsLoading(false);
    };

    return (
        <div className="container-fluid signin-container vh-100 w-100 m-0 p-0 d-flex align-items-center justify-content-center">
            <div className="col-8 col-md-4 d-flex flex-column justify-content-around align-items-center">
                <div className="logo text-center mb-3">
                    <img
                        src="/images/hyperglade-logo-black.svg"
                        alt="logo"
                        width="200px"
                    />
                </div>

                <div className="text-center mb-5">
                    {variant === "signin" && (
                        <Typography variant="display1">
                            Sign in to your business account
                        </Typography>
                    )}
                    {variant === "signup" && (
                        <Typography variant="display1">
                            Create an account to get started with Hyperglade
                        </Typography>
                    )}
                </div>
                <div className="text-center d-flex align-items-center justify-content-center w-100 mb-4">
                    <ConnectEmbed btnTitle="Sign in with Thirdweb" />
                    {/* <div className="mb-3">
                        <Button
                            variant="outline-primary"
                            size="large"
                            icon="/icons/google-icon.svg"
                            disabled
                        >
                            Sign in with Google
                        </Button>
                    </div> */}
                    {/* <div className="mb-3">
                        <Button
                            variant="outline-primary"
                            size="large"
                            icon="/icons/facebook-icon.svg"
                            className="mb-3"
                            disabled
                        >
                            Sign in with Facebook
                        </Button>
                    </div>

                    <div className="mb-3">
                        <Button
                            variant="outline-primary"
                            size="large"
                            icon="/icons/twitter-icon.svg"
                            className="mb-3"
                            disabled
                        >
                            Sign in with X
                        </Button>
                    </div> */}
                </div>
                {showToast && (
                    <ToastComponent message={toastMessage} type="Error" />
                )}
                {isLoading && (
                    <div className="d-flex justify-content-center align-items-center">
                        <Loading />
                    </div>
                )}

                <footer className="fixed-bottom p-3 text-center">
                    {variant === "signin" && (
                        <Typography variant="subtitle">
                            New to Hyperglade?{" "}
                            <Link to="/register" className="register-text">
                                Register now
                            </Link>
                        </Typography>
                    )}
                    {variant === "signup" && (
                        <Typography variant="subtitle">
                            Already have an account?{" "}
                            <Link to="/signin" className="register-text">
                                Sign in
                            </Link>
                        </Typography>
                    )}
                </footer>
            </div>
        </div>
    );
}
