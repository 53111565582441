import React, { useEffect, useState } from "react";
import { string_to_snake_case } from "../../../../utils/projectUtils";
import { Select, TextField } from "../../../../components/forms";

export const formConfigOptions = [
    {
        label: "Text Input",
        value: "text",
    },
    {
        label: "Date Input",
        value: "date",
    },
    {
        label: "Image Input",
        value: "image",
        name: "logo",
        // onFileSelected:{setFiles},
        // logoImage:{logoImage},
        // onChange:{onChange}
    },
];

export function FormRenderer({ formFields, setFormFields }){
    const [fieldsToValidate, setFieldsToValidate] = useState([]);
    const [dragOverIndex, setDragOverIndex] = useState(null);

    const [formGroups, setFormGroups]  = useState(state => formFields.filter(field => field.type === "group").map(field => ({ groupId: field['groupId']}) ))

    // Function to handle the addition of a form field
    const handleAddFormField = (e, fieldGroup="0") => {
        const newFieldIndex = formFields.length;
        setFormFields([
            ...formFields,
            {
                label: "",
                name: "",
                type: "",
                groupId: fieldGroup
            },
        ]);
        setFieldsToValidate([...fieldsToValidate, newFieldIndex]);
    };

    // Function to handle addition of a form group
    // Form groups are groups of fields that can repeat
    const handleAddFormGroup = () => {
        const newFieldIndex = formFields.length;
        setFormFields([
            ...formFields,
            {
                name: "Group",
                type: "group",
                groupId: `${(formGroups.length + 1)}` 
            },
        ]);
        setFormGroups([
            ...formGroups,
            {
                groupId: `${(formGroups.length + 1)}` 
            }
        ])
        setFieldsToValidate([...fieldsToValidate, newFieldIndex]);
    };

    // Function to handle the removal of a form field
    const handleRemoveFormField = (index) => {
        setFormFields(formFields.filter((_, i) => i !== index));
        setFieldsToValidate(fieldsToValidate.filter((i) => i !== index));
    };

    // Function to handle the change of a form field property
    const handleOnChangeFormFieldProp = (index, field, value) => {
        setFormFields(
            formFields.map((f, i) => {
                if (i !== index) {
                    return f;
                }
                if (field === "label") {
                    return {
                        ...f,
                        label: value,
                        name: string_to_snake_case(value || ""),
                    };
                } else {
                    return {
                        ...f,
                        [field]: value,
                    };
                }
            })
        );
    };

    // Function to handle the start of a drag event
    const handleDragStart = (e, index) => {
        e.dataTransfer.setData("draggedIndex", index);
    };

    // Function to handle the drag over event on a field
    const handleDragOverField = (e, index) => {
        e.preventDefault();
        setDragOverIndex(index);
    };

    // Function to handle the drop event
    const handleDrop = (e, index) => {
        const draggedIndex = e.dataTransfer.getData("draggedIndex");
        if (draggedIndex === index) return;
        const updatedFormFields = [...formFields];
        const [draggedField] = updatedFormFields.splice(draggedIndex, 1);
        updatedFormFields.splice(index, 0, draggedField);
        setFormFields(updatedFormFields);
        setDragOverIndex(null);
    };


    return (
        <div>
            <div className="m-3">
                {formFields.map((ff, idx) => {
                    // Render groups
                    if (ff["type"] === "group") {
                        return (
                            <div 
                                className='group-container'
                                key={idx}
                            >   
                                <div className="d-flex justify-content-between m-3 add-header">
                                    <div className="add-title">Group</div>
                                    <div className="d-flex align-items-center">
                                        {/* TODO: Removing a group */}
                                        {/* <div className="add-close" onClick={() =>handleRemoveFormField(idx)}>
                                            X Remove
                                        </div> */}
                                    </div>
                                </div>
                                <div className="m-3">
                                    {formFields.map((groupField, groupFieldId) => {
                                                    if(groupField['groupId'] !== ff['groupId'] || groupField['type'] === 'group'){
                                                        return null;
                                                    }

                                                    return (
                                                        <FormComponent
                                                            key={groupFieldId}
                                                            fieldName={groupField["name"]}
                                                            fieldLabel={groupField["label"]}
                                                            fieldType={groupField["type"]}
                                                            fieldIndex={groupFieldId}
                                                            fieldGroup={groupField["group"]}
                                                            onAddFormField={handleAddFormField}
                                                            onRemoveField={() =>
                                                                handleRemoveFormField(groupFieldId)
                                                            }
                                                            onChangeProp={(field, value) =>
                                                                handleOnChangeFormFieldProp(
                                                                    groupFieldId,
                                                                    field,
                                                                    value
                                                                )
                                                            }
                                                            onDragOver={handleDragOverField}
                                                            onDragStart={handleDragStart}
                                                            onDrop={handleDrop}
                                                            isDragOver={dragOverIndex === groupFieldId}
                                                        />
                                                    )   
                                                }
                                            )}
                                </div>
                                <div className='d-flex flex-row'>
                                    <div
                                        className="m-3 add-another-node"
                                        onClick={(e) => handleAddFormField(e, ff["groupId"])}
                                    >
                                        + Add form field
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    // Skip grouped fields
                    if(ff['groupId'] !== "0"){
                        return null;
                    }

                    return (
                        <FormComponent
                            key={idx}
                            fieldName={ff["name"]}
                            fieldLabel={ff["label"]}
                            fieldType={ff["type"]}
                            fieldIndex={idx}
                            fieldGroup={ff["group"]}
                            onAddFormField={handleAddFormField}
                            onRemoveField={() =>
                                handleRemoveFormField(idx)
                            }
                            onChangeProp={(field, value) =>
                                handleOnChangeFormFieldProp(
                                    idx,
                                    field,
                                    value
                                )
                            }
                            onDragOver={handleDragOverField}
                            onDragStart={handleDragStart}
                            onDrop={handleDrop}
                            isDragOver={dragOverIndex === idx}
                        />
                    )
                
                })}
            </div>
            <div className='d-flex flex-row'>
                <div
                    className="m-3 add-another-node"
                    onClick={handleAddFormField}
                >
                    + Add form field
                </div>
                <div
                    className="m-3 add-another-node"
                    onClick={handleAddFormGroup}
                >
                    + Add form group
                </div>
            </div>
        </div>
    )
}


function FormComponent({
    fieldName,
    fieldLabel,
    fieldType,
    fieldIndex,
    fieldGroup,
    onAddFormField,
    onRemoveField,
    onChangeProp,
    onDragStart,
    onDragOver,
    onDrop,
    isDragOver,
}) {
    // Handle the drag start event
    const handleDragStart = (e) => {
        onDragStart(e, fieldIndex);
    };

    // Handle the drag over event
    const handleDragOver = (e) => {
        e.preventDefault();
        onDragOver(e, fieldIndex);
    };

    // Handle the drop event
    const handleDrop = (e) => {
        onDrop(e, fieldIndex);
    };

    return (
        <div
            className={`add-container ${isDragOver ? "drag-over" : ""}`}
            key={fieldIndex}
            draggable
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
        >
            <div className="d-flex justify-content-between m-3 add-header">
                <div className="add-title">Form field - #{fieldIndex + 1}</div>
                <div className="d-flex align-items-center">
                    <div className="add-close" onClick={onRemoveField}>
                        X Remove
                    </div>
                    <div className="drag-icon">
                        <i className="bi bi-grip-vertical"></i>
                    </div>
                </div>
            </div>
            <div className="m-3">
                <TextField
                    name={`form_config_label_${fieldIndex}`}
                    label="Field Label"
                    value={fieldLabel}
                    onChange={(e) => onChangeProp("label", e.target.value)}
                    placeholder="Enter field label"
                />
            </div>
            <div className="m-3">
                <TextField
                    name={`form_config_name_${fieldIndex}`}
                    label="Field Name"
                    value={fieldName}
                    disabled={true}
                    onChange={(e) => onChangeProp("name", e.target.value)}
                />
            </div>
            <div className="m-3">
                <Select
                    options={formConfigOptions}
                    name={`form_config_type_${fieldIndex}`}
                    label="Field Type"
                    value={fieldType}
                    onChange={(e) => onChangeProp("type", e.target.value)}
                />
            </div>
        </div>
    );
}