import React, { useEffect, useMemo, useState } from "react";
import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import RecordForm from "./RecordForm";
import { Form } from "../../../components/forms";
import {
    useConfirmAssetMutation,
    useCreateAssetMutation,
    useGetAssetsTypeQuery,
    useGetNodesListQuery,
} from "../../../services/supplyChainService";
import { useSDK } from "@thirdweb-dev/react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useGetProjectDetailQuery } from "../../../services/projectService";
import { logEvent } from "../../../utils/amplitudeUtlis";
import Loading from "../../../components/LoadingSkeleton/Loading";
import { useUploadFileMutation } from "../../../services/fileManagementService";

const validationSchema = Yup.object().shape({
    asset_id: Yup.string()
        .max(6, "Product ID must be 6 character long")
        .min(6, "Product ID must be 6 characters long")
        .required("Product ID is required")
        .trim("The input must not be empty or just spaces")
        .required("The input must not be empty or just spaces"),
});

export default function AddRecordPage() {
    const sdk = useSDK();
    const navigate = useNavigate();
    const { id: projectId } = useParams();
    const { batchId } = useParams();

    const {
        data: project,
        error,
        isProjectLoading,
    } = useGetProjectDetailQuery(projectId);

    const [isLoading, setIsLoading] = useState(false);
    const [loadingStep, setLoadingStep] = useState("");
    const [selectedAssetType, setSelectedAssetType] = useState(null);
    const [createAsset] = useCreateAssetMutation();
    const [uploadFile] = useUploadFileMutation();
    const { data: nodesResponse, isLoading: isLoadingNodes } =
        useGetNodesListQuery(projectId);

    const { data: assetTypesResponse, isLoading: isLoadingAssetTypes } =
        useGetAssetsTypeQuery(projectId);

    const [groupRepeatCount, setGroupRepeatCount] = useState({});

    useEffect(() => {
        // Effect to keep track of the grouprepat counts
        // When select asset changes we set counts to 1 each
        if (selectedAssetType && selectedAssetType.formConfig?.fields) {
            setGroupRepeatCount(selectedAssetType.formConfig.fields
                .filter(field => field.type === "group")
                .reduce((acc, field) => ({ ...acc, [field.groupId]: 1 }), {}))
        }
    }, [selectedAssetType])

    const handleCloseClick = () => {
        // Go back
        navigate(-1);
        logEvent({
            eventName: "cancel_button_clicked",
            eventProperties: {
                page_name: "Add Record Page",
            },
        });
    };

    const nodeList = useMemo(() => {
        return nodesResponse?.data.map((node) => ({
            label: node.name,
            value: node.id,
        }));
    }, [nodesResponse]);

    const assetTypeList = useMemo(() => {
        return assetTypesResponse?.data?.map((assetType) => ({
            id: assetType.id,
            label: assetType.type_name,
            value: assetType.id,
            dependencyConfig: assetType.dependency_config,
            formConfig: assetType.form_config,
        }));
    }, [assetTypesResponse]);

    const handleAssetCreate = async (values) => {
        setIsLoading(true);
        setLoadingStep("Validating data...");

        logEvent({
            eventName: "record_creation_initiated",
            eventProperties: {
                page_name: "Add Record Page",
            },
        });

        // parse dependencies
        let dependencies = [];
        for (var dep in values.dependency) {
            dependencies.push({
                type: dep,
                asset_id: values.dependency[dep],
            });
        }

        let formConfig = [];
        for (let field in values.field) {
            
            // Check if the field is an image
            if (field.includes("group")){
                const groupId = field.replace('group', '');

                let valueSets = [];

                for(let groupField of values.field[field]){
                    let groupFields = [];

                    for(let key in groupField){
                        const assetTypeConfig = selectedAssetType.formConfig.fields.find(
                            (config) => config.name === key && config.groupId === groupId
                        )

                        if(!assetTypeConfig){
                            continue;
                        }

                        groupFields.push({
                            ...assetTypeConfig,
                            value: groupField[key]
                        })
                    }

                    valueSets.push(groupFields);
                }

                formConfig.push({
                    groupId: groupId,
                    label: "Group",
                    type: "group",
                    value: valueSets
                });

            }else{
                const assetTypeConfig = selectedAssetType.formConfig.fields.find(
                    (config) => config.name === field
                );

                if(!assetTypeConfig){
                    continue;
                }

                if (assetTypeConfig.type === "image") {
                    try {
                        const formData = new FormData();
                        formData.append("file", values.field[field]);
                        const uploadResponse = await uploadFile({ file: formData });
                        if (uploadResponse.error) {
                            throw new Error("Image upload failed");
                        }
    
                        console.log(uploadResponse, "uploadResponse");
                        formConfig.push({
                            ...assetTypeConfig,
                            value: uploadResponse.data?.data?.id,
                        });
                    } catch (error) {
                        toast.error("Image upload failed: " + error.message);
                        setIsLoading(false);
                        return;
                    }
                }else{
                    formConfig.push({
                        ...assetTypeConfig,
                        value: values.field[field],
                    });
                }
            }
        }

        try {
            console.log("Debug: Creating the asset in backend");
            setLoadingStep("Creating record...");
            console.log({
                id: projectId,
                asset_id: values.asset_id,
                asset_type: selectedAssetType.id,
                node: values.node,
                dependencies: dependencies,
                form_data: formConfig,
            });
            const response = await createAsset({
                id: projectId,
                asset_id: values.asset_id,
                asset_type: selectedAssetType.id,
                node: values.node,
                dependencies: dependencies,
                form_data: formConfig,
                batch_id: batchId,
            });

            if (response.error) {
                let errorMessage = "Could not create the asset record"; // Default error message
                if (
                    response.error.data &&
                    response.error.data.errors &&
                    response.error.data.errors.length > 0
                ) {
                    const firstErrorKey = Object.keys(
                        response.error.data.errors[0]
                    )[0];
                    const firstErrorMessage =
                        response.error.data.errors[0][firstErrorKey][0];
                    errorMessage = firstErrorMessage || errorMessage;
                }
                throw new Error(errorMessage);
            }

            logEvent({
                eventName: "record_creation_completed",
                eventProperties: {
                    record_id: values.asset_id,
                    project_id: projectId,
                },
            });

            const asset = response.data.data.asset;
            const instructions = response.data.data.instructions;

            console.log(asset);
            console.log(instructions);
            console.log("Debug: Asset created, now trying to execute txn");
            setLoadingStep("Doing blockchain magic...");

            for (var instruction of instructions) {
                console.log("Debug: Trying txn -", instruction);
                const contract = await sdk.getContractFromAbi(
                    instruction.contract,
                    instruction.abi
                );
                const txn = await contract.call(
                    instruction.command,
                    instruction.args,
                    {
                        gasLimit: 100000000,
                    }
                );
                console.log("Debug:", instruction.command, txn);
            }

            toast("Asset record added!");
            handleCloseClick();
        } catch (error) {
            const errorMessage =
                error.message || "An unexpected error occurred";
            if (errorMessage.includes("timeout")) {
                toast(
                    "Transaction to the blockchain is taking longer than usual. If it fails this record might be archived."
                );
            } else {
                toast.error(errorMessage);
            }

            logEvent({
                eventName: "record_creation_failed",
                eventProperties: {
                    errorMessage: error,
                },
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleOnAssetTypeSelectionChange = (event) => {
        const selection = event.target.value;
        const assetType = assetTypeList.find((at) => at.value === selection);

        if (assetType) {
            setSelectedAssetType(assetType);
        }
    };

    const handleAddRepeatingGroup = (groupId) => {
        setGroupRepeatCount({
            ...groupRepeatCount,
            [groupId]: 1 + (groupRepeatCount[groupId] || 0),
        })
    }

    const handleRemoveRepeatingGroup = (groupId) => {
        setGroupRepeatCount({
            ...groupRepeatCount,
            [groupId]: groupRepeatCount[groupId] > 1 ? groupRepeatCount[groupId]-1 : 1,
        })
    }

    /**
     * FIXME: Need to add proper loading indicators while these load
     */
    if (isLoadingAssetTypes || isLoadingNodes) {
        return (
            <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100vh" }}
            >
                <Loading />
            </div>
        );
    }

    return (
        <div className="h-100 min-vh-100 w-100 m-0 px-4">
            <div className="mt-3">
                <Typography variant="body1">
                    Overview / {project?.data?.name} / Add record
                </Typography>
                <div className="d-flex flex-column flex-sm-row justify-content-md-start justify-content-center align-items-center pt-3 pb-2 mb-3">
                    <div className="d-flex align-items-center flex-column flex-sm-row">
                        <Typography variant="h1">Add new record</Typography>
                    </div>
                    <div className="me-0 mb-2 mb-sm-0 p-md-0 p-4 pt-3 pe-md-1 ms-sm-auto d-flex justify-content-center justify-content-sm-start">
                        <Button
                            type="button"
                            variant="secondary"
                            fullWidth={false}
                            onClick={handleCloseClick}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
                <div className="divider-line mt-4"></div>
            </div>
            <div className="container col-xxl-8 px-4 mt-5">
                <div className="row align-items-center justify-content-center d-flex flex-row">
                    <div className="col-lg-10 mx-auto justify-content-center">
                        <Typography variant="h2">Record Details</Typography>
                        <Form
                            initialValues={{}}
                            onSubmit={handleAssetCreate}
                            enableReinitialize={true}
                            validationSchema={validationSchema}
                        >
                            <RecordForm
                                fieldType="text"
                                name="asset_id"
                                label="Product ID"
                            />
                            <RecordForm
                                fieldType="dropdown"
                                name="node"
                                label="Node"
                                options={nodeList}
                            />
                            <RecordForm
                                fieldType="dropdown"
                                name="asset_type"
                                label="Asset Type"
                                options={assetTypeList}
                                onChange={handleOnAssetTypeSelectionChange}
                            />

                            {selectedAssetType && (
                                <>
                                    {selectedAssetType.dependencyConfig?.dependencies?.map?.(
                                        (dep) => {
                                            return (
                                                <RecordForm
                                                    fieldType="dependency"
                                                    name={`dependency.${dep.type}`}
                                                    label={dep.label}
                                                    typeName={dep.type}
                                                />
                                            );
                                        }
                                    )}

                                    {selectedAssetType.formConfig?.fields?.map?.(
                                        (field) => {

                                            if(field.type === 'group'){
                                                let groupFieldRender = [];

                                                for(let repeat=0;repeat<groupRepeatCount[field.groupId];repeat++){
                                                    groupFieldRender.push(
                                                        <div className="field-group-container" key={`group_${field.groupId}_repeat_${repeat}`}>
                                                            <div className="field-group-header d-flex p-3 pb-0">
                                                                <div className="text-3">Group {field.groupId}</div>
                                                                {repeat > 0 && repeat === groupRepeatCount[field.groupId]-1 && 
                                                                    <div className="text-3 ms-auto field-group-action-remove" onClick={() =>handleRemoveRepeatingGroup(field.groupId)}>
                                                                        X Remove
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="mx-3 field-group-fields">
                                                                {
                                                                    selectedAssetType.formConfig.fields
                                                                    .filter(groupField => groupField.type !== 'group' && groupField.groupId === field.groupId)
                                                                    .map(groupField => {
                                                                        return (
                                                                            <RecordForm
                                                                                fieldType={groupField.type}
                                                                                name={`field.group${groupField.groupId}.${repeat}.${groupField.name}`}
                                                                                label={groupField.label}
                                                                            />
                                                                        )            
                                                                    })
                                                                }
                                                            </div>
                                                            <div className="field-group-actions">
                                                                {repeat === groupRepeatCount[field.groupId]-1 && 
                                                                    <div
                                                                        className="m-3 add-another-node"
                                                                        onClick={() => handleAddRepeatingGroup(field.groupId)}
                                                                    >
                                                                        + Add another value set
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                
                                                return groupFieldRender;
                                            }

                                            if(field.groupId && field.groupId !== '0'){
                                                return null;
                                            }

                                            return (
                                                <RecordForm
                                                    fieldType={field.type}
                                                    name={`field.${field.name}`}
                                                    label={field.label}
                                                />
                                            );
                                        }
                                    )}
                                </>
                            )}

                            <div className="my-4">
                                <Button
                                    type="submit"
                                    variant="primary"
                                    fullWidth={false}
                                    isLoading={isLoading}
                                    loadingText={loadingStep}
                                >
                                    Add records
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
}
