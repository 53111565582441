import React, { useMemo } from "react";
import Typography from "../../../components/Typography/Typography";
import CarousalImageCard from "../../../components/Card/CarousalImageCard";
import "../Trace/trace.scss";
import { useDownloadFileQuery } from "../../../services/fileManagementService";
import { getExplorerLink } from "../../../web3/helpers/utils";

function Section({
    title,
    description,
    location,
    mapImage,
    details,
    images,
    id,
    tokenId,
    txnHash,
    contractAddress,
    nodeFormData,
    chain
}) {

    const explorer_url = useMemo(() => getExplorerLink(chain), [chain]);

    return (
        <div className="px-2">
            <div className="row">
                <div className="col-12 col-md-8 offset-md-2 pt-2">
                    <div className="pt-2 ps-1">
                        <Typography
                            variant="display3"
                            className="m-0  d-flex justify-content-md-center"
                        >
                            {title}
                        </Typography>
                    </div>
                    <div className="pt-3">
                        <CarousalImageCard images={images} />
                        <div className="d-flex justify-content-center mt-3 text-start mx-2">
                            <Typography variant="body1" className="m-0">
                                {description}
                            </Typography>
                        </div>
                    </div>
                    <div className="col-12 mt-2">
                        <div className="card mx-2 mb-3 border-0">
                            <div className="row g-0">
                                <div className="col-3 col-md-2 d-flex align-items-center justify-content-center">
                                    <div
                                        style={{
                                            backgroundColor: "#E2F1FF",
                                            width: "50px",
                                            height: "50px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <img
                                            src="/icons/vuesax-bold-location.svg"
                                            className="img-fluid rounded-start"
                                            alt="..."
                                        />
                                    </div>
                                </div>
                                <div className="col-9 col-md-10">
                                    <div className="card-body">
                                        <Typography
                                            variant="body1"
                                            className="m-0"
                                        >
                                            {location}
                                        </Typography>
                                        <p className="card-text open-map">
                                            Open the map
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {details.map((item, index) => (
                        <div className="col-12 mt-2" key={index}>
                            <div className="card mx-2 mb-3 border-0">
                                {item.type === 'valueset' && 
                                    <>
                                    {item.value.map(groupItem => (
                                        <FormDetail item={groupItem} />
                                    ))}
                                    </>
                                }
                                {item.type !== 'valueset' && 
                                    <FormDetail item={item} />
                                }
                            </div>
                        </div>
                    ))}
                </div>
                <div className="w-100 d-flex justify-content-center mb-4">
                    {txnHash && (
                        <a
                            href={`${explorer_url}/tx/${txnHash}`}
                            className="btn btn-primary"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Verify
                        </a>
                    )}
                    {tokenId && (
                        <a
                            href={`${explorer_url}/token/${contractAddress}?a=${tokenId}`}
                            className="btn btn-primary ms-2"
                            target="_blank"
                            rel="noreferrer"
                        >
                            View Token
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
}

function FormDetail({ item, index }){

    return (
        <div className="row g-0">
            <div className="col-12">
                <div className="card-body">
                    <Typography
                        variant="body1"
                        className="m-0"
                        color="#70B8FF"
                    >
                        {item.key}
                    </Typography>
                    {item.type === "image" ? (
                        <ImageDetail
                            fileId={item.value}
                        />
                    ) : (
                        <Typography
                            variant="body1"
                            className="m-0"
                            color="#6C6C6F"
                        >
                            {item.value}
                        </Typography>
                    )}
                </div>
            </div>
        </div>
    )
}

function ImageDetail({ fileId }) {
    const { data, error, isLoading } = useDownloadFileQuery({ fileId });
    console.log(data?.data?.file, "Image");

    if (isLoading) return <p>Loading...</p>;
    if (error) return <p>Error loading image</p>;
    return <img src={data?.data?.file} alt="Detail" className="img-fluid" />;
}

export default Section;
