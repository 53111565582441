import { useState, useEffect } from "react";
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button/Button";
import "./onboarding.scss";
import TextField from "../../components/forms/TextField";
import { Form, Select } from "../../components/forms";
import { useCreateTenantMutation } from "../../services/tenantService";
import {
    useProfileQuery,
    useUpdateUserMutation,
} from "../../services/userService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { logEvent } from "../../utils/amplitudeUtlis";
import * as Yup from "yup";

export default function OnboardingPage() {
    const [screen, setScreen] = useState(1); // Default active tab is 1
    const [createTenant] = useCreateTenantMutation();
    const [updateUser] = useUpdateUserMutation();
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [jobRole, setJobRole] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [industry, setIndustry] = useState("");
    const [noOfEmployees, setNoOfEmployees] = useState("");
    const { data: profileData } = useProfileQuery();
    let user = profileData?.data || null;
    const role = user?.role;
    const regStatus = user?.reg_status;
    const tenantId = user?.tenant;
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (tenantId && regStatus === "COMPLETED") {
            navigate("/overview");
        }
    }, [tenantId, navigate]);

    const handleScreenClick = (tabNumber) => {
        setScreen(tabNumber);
    };

    const handleAdminFormSubmit = async (values) => {
        try {
            logEvent({
                eventName: "tenant_onboarding_initiated",
                eventProperties: {
                    timestamp: new Date().toLocaleString(),
                },
            });
            if (screen === 1) {
                setFirstName(values.firstName);
                setLastName(values.lastName);
                setScreen(2);
            } else if (screen === 2) {
                const response = await createTenant({
                    first_name: firstName,
                    last_name: lastName,
                    name: values.companyName,
                    job_role: values.jobRole,
                    employee_count: values.noOfEmployees,
                    industry: values.industry,
                });

                if (response.error) {
                    toast(response.error.data.errors[0], { type: "error" });
                    logEvent({
                        eventName: "tenant_onboarding_failed",
                        eventProperties: {
                            timestamp: new Date().toLocaleString(),
                            error: response.error.data.errors[0],
                        },
                    });
                    throw new Error("Error completing registration");
                }
                toast("Registration complete!");
                logEvent({
                    eventName: "tenant_onboarding_completed",
                    eventProperties: {
                        tier: response?.data?.data?.current_tier,
                        timestamp: new Date().toLocaleString(),
                    },
                });
                // FIXME: patch to reload the user profile
                window.location = "/overview";
            }
        } catch (error) {
            toast.error("Error completing the onboarding");
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleUserFormSubmit = async (values) => {
        try {
            logEvent({
                eventName: "user_onboarding_initiated",
                eventProperties: {},
            });
            const response = await updateUser({
                first_name: values.firstName,
                last_name: values.lastName,
            });

            if (response.error) {
                toast(response.error.data.errors[0], { type: "error" });
                logEvent({
                    eventName: "user_onboarding_failed",
                    eventProperties: { error: response.error.data.errors[0] },
                });
                throw new Error("Error completing registration");
            }
            logEvent({
                eventName: "user_onboarding_completed",
                eventProperties: { tier: response?.data?.data?.current_tier },
            });
            toast("Registration complete!");
        } catch (error) {
            console.error(error);
        }
    };

    const nameFormSchema = Yup.object().shape({
        firstName: Yup.string()
            .required("First name must not be empty or just spaces")
            .trim("First name must not be empty or just spaces"),
        lastName: Yup.string()
            .required("Last name must not be empty or just spaces")
            .trim("Last name must not be empty or just spaces"),
        jobRole: Yup.string()
            .required("Job role must not be empty or just spaces")
            .trim("Job role must not be empty or just spaces"),
    });

    const CompanyFormSchema = Yup.object().shape({
        companyName: Yup.string()
            .required("Company name must not be empty or just spaces")
            .trim("Company name must not be empty or just spaces"),
        industry: Yup.string(),
        noOfEmployees: Yup.string(),
    });

    return (
        <div className="d-flex justify-content-center">
            <div className="container-fluid px-3 py-3 col-12 col-md-8 col-lg-6">
                {role === "ADMIN" && regStatus === "CREATED" && (
                    <div className="pt-4">
                        <div className="text-center">
                            <Typography variant="h1">
                                Welcome to Hyperglade
                            </Typography>
                            <Typography variant="body1">
                                We're excited to get you up and running.
                            </Typography>
                        </div>
                        <Typography variant="h4" className="pt-4">
                            {screen === 1
                                ? "Tell us about yourself"
                                : "Tell us about your organization"}
                        </Typography>
                        <Form
                            initialValues={{
                                firstName: firstName,
                                lastName: lastName,
                                jobRole: jobRole,
                                companyName: companyName,
                                noOfEmployees: noOfEmployees,
                                industry: industry,
                            }}
                            onSubmit={(values) => {
                                if (screen === 1) {
                                    setFirstName(values.firstName);
                                    setLastName(values.lastName);
                                    handleScreenClick(2);
                                } else {
                                    handleAdminFormSubmit(values);
                                }
                            }}
                            validationSchema={
                                screen === 1
                                    ? nameFormSchema
                                    : CompanyFormSchema
                            }
                        >
                            {screen === 1 && (
                                <div className="row">
                                    <div className="col-12 mt-4">
                                        <TextField
                                            required
                                            id="firstName"
                                            name="firstName"
                                            label="First Name"
                                            placeholder="First Name"
                                        />
                                    </div>
                                    <div className="col-12 mt-4">
                                        <TextField
                                            required
                                            id="lastName"
                                            name="lastName"
                                            label="Last Name"
                                            placeholder="Last Name"
                                        />
                                    </div>
                                    <div className="col-12 mt-4">
                                        <TextField
                                            required
                                            id="jobRole"
                                            name="jobRole"
                                            label="Job Role"
                                            placeholder="Job Role"
                                        />
                                    </div>
                                </div>
                            )}
                            {screen === 2 && (
                                <div className="row">
                                    <div className="col-12 mt-4">
                                        <TextField
                                            required
                                            id="companyName"
                                            name="companyName"
                                            label="Company name"
                                        />
                                    </div>
                                    <div className="col-12 mt-4">
                                        <Select
                                            required
                                            id="noOfEmployees"
                                            name="noOfEmployees"
                                            label="How many employees work there?"
                                            options={[
                                                {
                                                    value: "1-10",
                                                    label: "1-10",
                                                },
                                                {
                                                    value: "11-50",
                                                    label: "11-50",
                                                },
                                                {
                                                    value: "51-200",
                                                    label: "51-200",
                                                },
                                                {
                                                    value: "201-500",
                                                    label: "201-500",
                                                },
                                                {
                                                    value: "501-1000",
                                                    label: "501-1000",
                                                },
                                                {
                                                    value: "1001-5000",
                                                    label: "1001-5000",
                                                },
                                                {
                                                    value: "5001-10000",
                                                    label: "5001-10000",
                                                },
                                                {
                                                    value: "10001+",
                                                    label: "10001+",
                                                },
                                            ]}
                                        />
                                    </div>

                                    <div className="col-12 mt-4">
                                        <Select
                                            id="industry"
                                            name="industry"
                                            label="What industry do you operate in?"
                                            options={[
                                                {
                                                    value: "Agriculture",
                                                    label: "Agriculture",
                                                },
                                                {
                                                    value: "Automotive",
                                                    label: "Automotive",
                                                },
                                                {
                                                    value: "Banking",
                                                    label: "Banking",
                                                },
                                                {
                                                    value: "Construction",
                                                    label: "Construction",
                                                },
                                                {
                                                    value: "Education",
                                                    label: "Education",
                                                },
                                                {
                                                    value: "Energy",
                                                    label: "Energy",
                                                },
                                                {
                                                    value: "Healthcare",
                                                    label: "Healthcare",
                                                },
                                                {
                                                    value: "Hospitality",
                                                    label: "Hospitality",
                                                },
                                                {
                                                    value: "Information Technology",
                                                    label: "Information Technology",
                                                },
                                                {
                                                    value: "Manufacturing",
                                                    label: "Manufacturing",
                                                },
                                                {
                                                    value: "Media",
                                                    label: "Media",
                                                },
                                                {
                                                    value: "Telecommunications",
                                                    label: "Telecommunications",
                                                },
                                                {
                                                    value: "Transport",
                                                    label: "Transport",
                                                },
                                                {
                                                    value: "Other",
                                                    label: "Other",
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="mt-4">
                                <Button
                                    variant="primary"
                                    type="submit"
                                    fullWidth={true}
                                >
                                    {screen === 1 ? "Continue" : "Get Started"}
                                </Button>
                            </div>
                        </Form>
                    </div>
                )}
                {role === "USER" && regStatus === "ONBOARDED" && (
                    <div className="container col-xxl-8 px-4">
                        <div className="pt-3">
                            <Typography variant="h2">
                                Let's set up your account
                            </Typography>

                            <Typography variant="body1">
                                Fill the following information required in order
                                to set up your account
                            </Typography>
                        </div>
                        <Form
                            initialValues={{
                                firstName: firstName,
                                lastName: lastName,
                            }}
                            onSubmit={handleUserFormSubmit}
                            validationSchema={nameFormSchema}
                        >
                            <>
                                <div className="w-100 mt-4">
                                    <TextField
                                        required
                                        id="firstName"
                                        name="firstName"
                                        label="First Name"
                                        style={{
                                            width: "100%",
                                        }}
                                    />
                                </div>
                                <div className="w-100 mt-4">
                                    <TextField
                                        required
                                        id="lastName"
                                        name="lastName"
                                        label="Last Name"
                                        style={{
                                            width: "100%",
                                        }}
                                    />
                                </div>
                            </>
                            <div className="mt-4">
                                <Button
                                    variant="primary"
                                    type="submit"
                                    fullWidth={false}
                                >
                                    Get Started
                                </Button>
                            </div>
                        </Form>
                    </div>
                )}
            </div>
        </div>
    );
}
